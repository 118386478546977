import "./header.css";

export default function Header() {
    return (
        <div className="header">
            <div className="headerTitles">

            <span className="headerTitlelg">unsung</span>
            

            </div>
        </div>
    )

}
