import "./landing.css";
import Header from "../../components/header/Header";

export default function Landing() {


    return (

        <div className="landing">
        <div className="landingContents">
        <Header/>
        </div>



        </div>

    )
}
